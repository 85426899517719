import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";

import RelatedContent from "../../components/RelatedContent";

const breadCrumbLevels = {
  Hjem: "/",
  // Blogg: "/blog",
  "Analyse Rådgivning": "/analyse-rådgivning"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/analytics-consulting"
);

const AnalyticsConsulting = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Analytics Consulting som en tjeneste for Google Analytics & Co."
        description="Sertifiserte analysetjenester på nett og eksternt for webanalyse med Google Tag Manager, Google Analytics & Co."
        lang="no"
        image="google-analytics-setup-hero"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <Img
          src="google-analytics-setup/google-analytics-setup-hero.jpg"
          alt="mann som jobber med Google Analytics-oppsett på laptop"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Analytics Consulting</H>
        <p>
          Bedrifter og eiere ønsker å vite hvordan de kan optimalisere sin digitale strategi.
          <br />
          Hvis de kunne basere beslutninger på data, kunne de redusere risikoen ved å investere i feil kanaler.
        </p>
        <p>Men for å komme dit, må noen ting bestemmes først.</p>
        <ul>
          <li>Hva skal spores? Hva trenger vi å vite?</li>
          <li>Hvordan skal vi samle inn dataene?</li>
          <li>Hvordan skal vi teste innsikter og avdekke feil antakelser?</li>
          <li>Hvordan skal dataene visualiseres for de relevante teamene?</li>
          <li>Hva skjer etter den første runden med endringer? Skal vi revidere våre antakelser?</li>
          <li>Hvordan kan vi innføre en kontinuerlig syklus av måling, dataanalyse, testing og implementering?</li>
        </ul>
        <p>
          <strong>Analytics consulting</strong> veileder deg gjennom denne reisen. Fra data <strong>innsamling</strong>
          , <strong>analyse</strong>, til å ta <strong>datadrevne beslutninger</strong>. Til slutt vil du ha en
          kontinuerlig <strong>optimaliseringsprosess</strong> for din online virksomhet.
        </p>
        <H as="h2">Hva er analytics consulting?</H>
        <p>
          Analytics consulting er en tjeneste som dekker veiledning innen alle webanalyseteknikker. Fra{" "}
          <Link to="/no/google-analytics-oppsett">oppsett av Google Analytics</Link> med de riktige KPIene, til integrering
          av tredjepartsdata. Å gjøre data tilgjengelig for teamene gjennom datavisualisering er et annet steg. Målet
          er å innføre en kontinuerlig prosess for datainnsamling, analyse, implementering og gjennomgang.
        </p>
        <H as="h4">Nøkkelområder for analytics consulting-tjenester</H>
        <ul>
          <li>Planlegging av datainnsamling, analyse og optimaliseringssykluser</li>
          <li>Valg og implementering av analyserverktøy</li>
          <li>Definering av KPIer og målinger</li>
          <li>Måling av brukeradferd</li>
          <li>Brukersegmentering</li>
          <li>Dataintegrasjon med tredjepartstjenester</li>
          <li>
            {/* <Link to="/no/data-visualisering"> */}
            Datavisualisering
            {/* </Link> */}
          </li>
        </ul>
        <H as="h2">Webanalysehjelp for alle typer nettsteder</H>
        <p>Det finnes ikke én analyttisk løsning som passer alle nettsteder.</p>
        <p>
          Hvis nettstedet ditt kun fokuserer på innhold, for eksempel, bør sporing også gjøre det. Noen få brukeradferd- og innholdsmetrikker er allerede nok til å se hva besøkende er ute etter.
        </p>
        <p>
          På e-handelsnettsteder er brukeradferd også viktig, men vanligvis er det bedre målinger tilgjengelig.{" "}
          <br /> En legg i handlekurv-knapp, for eksempel, indikerer mer interesse enn bare en produktvisning. Det samme gjelder zooming inn på et produktbilde eller valg av fargevarianter.
        </p>
        <p>
          Merk at i begge scenariene prøver vi å finne ut hvor interessert besøkende er i innholdet. Men avhengig av typen nettsted velger vi forskjellige målinger.
        </p>
        <p>
          E-handelsnettsteder tilbyr flere funksjoner, så vi har flere indikatorer for brukerens interesse. Teksttunge nettsteder derimot, sporer bredere målinger, siden det ikke er bedre indikatorer å utnytte.
        </p>
        <p>
          Å bruke spesifikke indikatorer over bredere, er veldig viktig for re-targeting, for eksempel. På Facebook, Instagram og Google-annonser bør målgruppen være så presis som mulig, siden vi betaler per visning.{" "}
          <br /> Så, avhengig av typen nettsted, er det forskjellige datapunkter å samle inn.
        </p>
        <p>
          Annonseutgifter, for eksempel, gjennomgås basert på konverteringer. Derfor har konverteringssporing
          {/* </Link> */} kritisk betydning for {/* <Link to="/no/ecommerce-analytics"> */}
          e-handelsanalyse.
          {/* </Link>. */}
        </p>
        <p>
          Helse- eller finansnettsteder stiller mange spørsmål til besøkende gjennom skjemaer. De går mye mer i detalj enn andre nettsteder. Det er fordi de har komplekse produkter og derfor må forklare mer. Også, de alternative kostnadene, relatert til feil produktvalg, er mye høyere.
        </p>
        <p>
          På slike nettsteder er optimalisering av kundereisen for å lede besøkende gjennom trakt, hovedfokuset. Ytterligere feilsporing for innsending av skjemaer vil sikre en friksjonsfri opplevelse.
        </p>
        <p>
          Som du kan se, finnes det mange forskjellige typer nettsteder og avhengig av forretningsmodellen må ulike KPIer spores. Derfor vurderer en{" "}
          <Link to="/no/google-analytics-konsulent">Google Analytics-konsulent</Link> alle disse faktorene
          og hjelper deg med å ta de riktige beslutningene for ditt analyttiske oppsett.
        </p>
        <H as="h2">Fordeler med Google Analytics Consulting</H>
        <p>
          Fordelen med Google Analytics consulting er tilgangen til data av høy kvalitet. Det gjør det mulig for deg å svare på viktige forretningsspørsmål.
        </p>
        <p>
          Når du kjenner de best konverterende markedsføringskanalene og kampanjene, vet du hvor du skal sette budsjettet ditt. Å kjenne til de best ytende kategoriene, temaene eller fargevariantene er også en stor hjelp ved ressursallokering.{" "}
        </p>
        <p>
          Hjelp fra en <Link to="/no/google-analytics-konsulent">Google Analytics-konsulent</Link> setter fokus på de
          riktige målingene og sikrer kvalitet. Ikke bare det, det sørger for at du fullfører arbeidet med å bli en
          "datadrevet" virksomhet.
        </p>
        <p>
          Data og analyse i seg selv fører ikke direkte til fortjeneste. Analyttiske konsulentfirmaer stresser ikke dette nok. <br /> Webanalyse gir data for å svare på forretningsspørsmål og oppdage salgsproblemer.
          Bruken av dataene fører til slutt til forretningsgevinster. <br />
          Så en Google Analytics-konsulenttjeneste gir deg fordelen av å finne svar på dine spørsmål.
        </p>
        <p>
          På samme måte viser et bilpanel informasjon om hastighet, oljetrykk og motortemperatur. Når du kjører, hjelper det deg å bestemme om du trenger å bremse eller om det er noe galt. Dette er en stor fordel. Men, panelet er ikke nødvendigvis nødvendig for å kjøre bilen. Det er mulig å kjøre den uten informasjon og likevel komme frem til destinasjonen.
        </p>
        <p>
          Det samme gjelder analyttiske konsulenttjenester. De er ikke nødvendige hvis du bare driver en blogg og vil vite hvor mange besøkende du har per måned.
          <br />
          Men hvis du enten tjener penger på nettstedet ditt eller investerer mye i det, er sjansene store for at du vil optimalisere salg og kostnader.
          <br /> Og det er her analyttiske konsulenttjenester hjelper deg med å gjøre nettopp det.
        </p>
        {/* <RelatedContent /> */}
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default AnalyticsConsulting;